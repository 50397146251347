import React, { useState, useEffect } from "react";
import { useWindowScroll } from "react-use";

// Styles
import * as cls from './bottom-bar.module.scss';

// Icons
import ArrowTop from '../../assets/images/arrow-top.svg';

export function BottomBar() {
  const { y: pageYOffset } = useWindowScroll();
  const [visible, setVisibility] = useState(false);

  useEffect(() => {
    if (pageYOffset > 500) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  }, [pageYOffset]);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  if (!visible) {
    return false;
  }

  return (
    <>
      <button className={`${cls.scroll_top_btn} ${visible && cls.sticky}`} onClick={scrollToTop}>
        <ArrowTop />
      </button>
      <div className={`${cls.bottom_bar} ${visible && cls.sticky}`}>
        <div className="container">
          <button className={cls.bottom_bar_btn} onClick={scrollToTop}>
            Jetzt (Ver)-Erbhilfe erhalten
          </button>
        </div>
      </div>
    </>
  );
}
