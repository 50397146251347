import React, { useState } from 'react'

import * as cls from './blockOtherServices.module.scss'

import ArrowRight from 'assets/images/arrow-right.svg'
import InfoIcon from 'assets/images/info-icon.svg'

export function BlockOtherServices({ tabs }) {
  const [selectedTab, setSelectedTab] = useState(0)

  if (!tabs) {
    return <></>
  }

  const changeTab = (e, index) => {
    e.preventDefault()
    setSelectedTab(index)
  }

  return (
    <div className={cls.tab_box}>
      <div className={cls.tab_box_info}>
        <InfoIcon />
        <p>Weitere Leistungen</p>
      </div>
      <div className={cls.tab_box_inner}>
        <ul className={cls.tabs}>
          {
            tabs.map((item, index) =>
              <li key={index} className={selectedTab === index ? `${cls.active}` : ''}>
                <a
                  href={`#${index}`}
                  onClick={(e) => changeTab(e, index)}
                >
                  {item.tabTitle}
                </a>
              </li>
            )
          }
        </ul>

        <div className={cls.content_tabs}>
          {
            tabs.map((item, index) =>
              <div key={index} className={selectedTab === index ? `${cls.content} ${cls.active}` : `${cls.content}`}>
                <h4>{item.title}</h4>
                <p>{item.text}</p>
                <a href={item?.button?.url}>{item?.button?.title} <ArrowRight /></a>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}
