import React from 'react'

import { BottomBar } from 'components/BottomBar'
import { Header } from 'components/Header'
import { BlockOtherServices } from 'components/Header/components/BlockOtherServices'
import InfoSection from '../../../UI/InfoSection'

import { Blocks } from 'components/Blocks'
import Seo from 'components/seo'

import * as cls from 'components/pages/articles/articles.module.scss'

import AttentionIcon from '~/assets/images/attention.svg';
import QuestionIcon from '~/assets/images/question.svg';

const Homepage = ({ pageContext: { pageData } }) => {
  return (
    <>
      <Seo title={pageData.title} meta={pageData.seo} />

      <BottomBar />
      <Header
        h1={pageData.header.h1}
        h2={pageData.header.h2}
        theses={pageData.header.theses}
        button={pageData.header.button}
        rightBlock={
          <div className="is-hidden-touch">
            <BlockOtherServices tabs={pageData?.blockOtherServices?.items} />
          </div>
        }
        breadcrumbs={[{
          title: pageData.title,
        }]}
        isWide
      />

      {
        pageData?.blockInfoAtBeginning
        && (
          <div style={{ marginTop: '60px' }}>
            <InfoSection
              title={pageData?.blockInfoAtBeginning?.title}
              text={pageData?.blockInfoAtBeginning?.text}
              flagIcon={<AttentionIcon className={cls.flag_icon} />} />
          </div>
        )
      }

      <Blocks items={pageData.blocks} />

      {
        pageData?.blockInfoAtEnd
        && (
          <InfoSection
            title={pageData?.blockInfoAtBeginning?.title}
            text={pageData?.blockInfoAtBeginning?.text}
            flagIcon={<QuestionIcon className={cls.flag_icon} />}
            background="var(--yellowLight)" />
        )
      }
    </>
  )
}

export default Homepage
