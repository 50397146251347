import React from 'react';

import HtmlParser from '../components/HtmlParser';

import * as cls from '../components/pages/articles/articles.module.scss';

const InfoSection = ({ isContainer = true, title, text, flagIcon, background }) => {
  return (
    <div className={cls.info_section}>
      {isContainer ? (
        <div className="container">
          <div className={cls.main__thing} style={{ background }}>
            {flagIcon}
            <h3 className={`main__subtitle ${cls.article__subtitle}`}>
              {title}
            </h3>
            <HtmlParser htmlString={text} />
          </div>
        </div>
      ) : (
        <div className={cls.main__thing} style={{ background }}>
          {flagIcon}
          <h3 className={`main__subtitle ${cls.article__subtitle}`}>
            {title}
          </h3>
          <HtmlParser htmlString={text} />
        </div>
      )}
    </div>
  );
};

export default InfoSection;
