// extracted by mini-css-extract-plugin
export var article__body = "articles-module--article__body--dy39R";
export var article__body_inner = "articles-module--article__body_inner--79FvT";
export var article__content = "articles-module--article__content--tdaJC";
export var article__subtitle = "articles-module--article__subtitle--grMQ0";
export var article__date = "articles-module--article__date--Mtt7j";
export var article__date_text = "articles-module--article__date_text--SZvib";
export var calendar = "articles-module--calendar--b2Bf6";
export var time = "articles-module--time--JfTfB";
export var info_section = "articles-module--info_section--vpDhz";
export var main__thing = "articles-module--main__thing--A7nPO";
export var flag_icon = "articles-module--flag_icon--T4pE-";
export var delay = "articles-module--delay--Pcb6Y";
export var poster = "articles-module--poster--fZx5i";
export var article__img = "articles-module--article__img--P9uVl";